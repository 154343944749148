<template>
  <form
    class="experience-form edution-form"
    :class="{
      'experience-form--active': isActive,
    }"
    :key="refreshForm"
    @submit.prevent="submitForm"
  >
    <div class="preview">
      <div class="preview-heading">
        <h3 class="preview-heading__title">
          {{
            experience.college_name ? experience.college_name : "School Name"
          }}
          <span
            >(
            {{
              experience.college_location
                ? experience.college_location
                : "Location"
            }}
            )</span
          >
        </h3>

        <p class="preview-heading__dates">
          {{ previewDate }}
        </p>
      </div>

      <div class="preview-controls-wrap" v-click-outside="closeMobileControls">
        <button
          class="mobile-control-btn"
          :class="{ 'mobile-control-btn--opened': isOpenMobileControls }"
          @click.stop.prevent="toggleMobileControls"
        >
          <AppIcon
            componentName="DotsVerticalIcon"
            class="control-btn-icon control-btn-icon--default"
          />
          <AppIcon
            componentName="CloseIcon"
            class="control-btn-icon control-btn-icon--close"
          />
        </button>

        <transition name="fade">
          <div
            v-if="isMobileDisplay ? isOpenMobileControls : true"
            class="preview-controls"
          >
            <button
              type="button"
              class="preview-controls__btn preview-controls__btn--move"
            >
              <AppIcon componentName="MoveIcon" />
            </button>

            <span class="preview-controls__separator"></span>

            <button
              type="button"
              class="preview-controls__btn"
              @click.prevent="onClickEditForm"
            >
              <AppIcon componentName="EditIcon" />
            </button>

            <span class="preview-controls__separator"></span>

            <button
              class="preview-controls__btn"
              @click.prevent="removeExperience"
            >
              <AppIcon componentName="TrashIcon" />
            </button>
          </div>
        </transition>
      </div>
    </div>

    <transition name="fade">
      <span
        v-if="activeId !== null && !isActive"
        class="experience-form-mask"
      ></span>
    </transition>

    <div class="row creation-form-row">
      <div class="creation-form__col col-xl-6">
        <AppAutoComplete
          class="creation-form-field"
          label="School name"
          types="establishment"
          :value="experienceData.college_name"
          :isInvalid="$v.experienceData.college_name.$error"
          @input="setField($event, 'college_name')"
          @address="setLocation($event)"
          ref="collegeName"
        >
          <template v-slot:error>
            <p v-if="!$v.experienceData.college_name.required">
              This field is required
            </p>
          </template>
        </AppAutoComplete>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="creation-form-field"
          label="School location"
          :value="experienceData.college_location"
          @input="setField($event, 'college_location')"
          ref="collegeLocation"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppSelect
          v-if="educationLevels"
          label="Level of education"
          :initValue="experienceData.level_of_education"
          :options="educationLevels"
          @select="onSelect($event.content, 'level_of_education')"
        />
      </div>

      <div v-if="diffDegree" class="creation-form__col col-xl-6">
        <AppField
          class="creation-form-field"
          label="Enter a different degree"
          :value="experienceData.level_of_education"
          @input="setField($event, 'level_of_education')"
        >
        </AppField>
      </div>
      <div class="creation-form__col col-xl-12">
        <AppFieldSelect
          class="creation-form-field"
          label="Field of study"
          :disabled="isDisabled"
          :value="experienceData.field_of_study"
          @input="setField($event, 'field_of_study')"
          :showResultBox="false"
          ref="fieldOfStudy"
          :getOptionHandler="getStudyField"
        >
        </AppFieldSelect>
      </div>

      <div class="creation-form__col col-xl-6">
        <date-picker
          popupClass="month-picker"
          ref="datepicker"
          v-model="experienceData.graduation_month"
          type="month"
          format="YYYY-MM-DDTHH:mm:ss.sss"
          value-type="format"
        >
          <template v-slot:input>
            <AppDateField
              class="creation-form-field"
              label="Graduation month"
              type="month"
              isBlockedWriting
              :value="experienceData.graduation_month"
              @change="setField($event, 'graduation_month')"
            >
            </AppDateField>
          </template>
        </date-picker>
      </div>

      <div class="creation-form__col col-xl-6">
        <date-picker
          ref="datepicker"
          v-model="experienceData.education_year"
          type="year"
          format="YYYY-MM-DDTHH:mm:ss.sss"
          value-type="format"
        >
          <template v-slot:input>
            <AppDateField
              class="creation-form-field"
              label="Graduation year"
              type="year"
              isBlockedWriting
              :value="experienceData.education_year"
              @change="setField($event, 'education_year')"
            >
            </AppDateField>
          </template>
        </date-picker>
      </div>

      <div class="creation-form__col col-xl-12">
        <input type="hidden" :value="experience.training_programs" />
        <AppTextEditor
          label="List of the schools and training programs"
          :value="experience.training_programs"
          enablePrefilled
          @searchPrefilled="getPrefilled"
          @input="setField($event, 'training_programs')"
          preFillPlaceHolder="Search by Keyword"
          @openPrefilled="openPrefilled"
          @closePrefilled="closePrefilled"
          v-if="isActive"
          :isEditing="isEditing"
        >
        </AppTextEditor>
      </div>
    </div>

    <div v-show="isEditing" class="experience-form-btns">
      <AppButton
        type="submit"
        title="Save"
        class="experience-form-btns__item experience-form-btns__item--submit"
        className="primary"
      />

      <button
        type="button"
        class="experience-form-btns__item experience-form-btns__item--cancel"
        @click.prevent="cancel"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import { experienceFormMixin } from "@/mixins/ExperienceFormMixin";

import { LOCAL_COMPONENTS_NAMES } from "@/helpers/components-names";
import AppTextEditor from "@/components/ui/AppTextEditor";
import AppDateField from "@/components/ui/AppDateField";
import AppFieldSelect from "@/components/ui/AppFieldSelect";
import AppAutoComplete from "@/components/ui/AppAutoComplete";

import { format } from "date-fns";

$(function () {
  $(".mx-datepicker").bind("dblclick", function () {
    $(".mx-datepicker").val();
  });
});
export default {
  name: LOCAL_COMPONENTS_NAMES.EDUCATION_FORM,
  mixins: [experienceFormMixin],
  components: {
    AppTextEditor,
    DatePicker,
    AppDateField,
    AppFieldSelect,
    AppAutoComplete,
  },

  provide() {
    const prefilled = {};

    Object.defineProperty(prefilled, "prefilledData", {
      enumerable: true,
      get: () => this.prefilledData,
    });

    return {
      prefilled,
    };
  },
  data() {
    return {
      // graduation_month: "",
      // education_year: "",
      isDisabled: false,
      diffDegree: false,
    };
  },
  props: {
    experience: {
      type: Object,
      required: true,
    },

    activeId: {
      type: [Number, String],
    },

    isMobileDisplay: {
      type: Boolean,
      default: false,
    },

    educationLevels: {
      type: Array,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      experienceData: {
        college_name: {
          required(value) {
            let cName = this.experienceData.college_name
              ? this.experienceData.college_name
              : "";
            let cLocation = this.experienceData.college_location
              ? this.experienceData.college_location
              : "";
            let cEducation = this.experienceData.level_of_education
              ? this.experienceData.level_of_education
              : "";
            let cStudy = this.experienceData.field_of_study
              ? this.experienceData.field_of_study
              : "";
            let cMonth = this.experienceData.graduation_month
              ? this.experienceData.graduation_month
              : "";
            let cYear = this.experienceData.education_year
              ? this.experienceData.education_year
              : "";
            let cExperience = this.experience.training_programs
              ? this.experience.training_programs
              : "";
            if (
              (value == null || value == "") &&
              (cLocation != "" ||
                cEducation != "" ||
                cStudy != "" ||
                cMonth != "" ||
                cYear != "" ||
                cExperience != "")
            ) {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };
  },
  computed: {
    previewDate() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // var isFirefox = typeof InstallTrigger !== "undefined";
      const formattedTime = [];

      if (this.experienceData.graduation_month) {
        const graduationMonth = new Date(this.experienceData.graduation_month);
        const formatted_graduation_month = month[graduationMonth.getMonth()];
        formattedTime.push(formatted_graduation_month);
      }

      if (this.experienceData.education_year) {
        let formatted_education_year = "";
        const educationYear = new Date(this.experienceData.education_year);
        formatted_education_year = ` ${educationYear.getFullYear()}`;
        formattedTime.push(formatted_education_year);
      }

      return formattedTime.join("");
    },
  },

  methods: {
    ...mapActions({
      getStudyField: "resumeCreation/getStudyField",
    }),
    setLocation(content) {
      this.$refs.collegeName.$el.querySelector("input").value =
        content.placeResultData.name;
      this.setField(content.placeResultData.name, "college_name");
      this.setField(
        content.addressData.locality ||
          content.addressData.administrative_area_level_1,
        "college_location"
      );
      this.$refs.collegeLocation.inputValue =
        content.addressData.locality ||
        content.addressData.administrative_area_level_1;
      this.$refs.collegeLocation.blur();
    },
    async getPrefilled(querySearch) {
      try {
        const prefilled = await this.$store.dispatch(
          "resumeCreation/getResumeEducationPrefilled",
          querySearch
        );

        this.$set(this, "prefilledData", prefilled);
      } catch (e) {
        console.error(e);
      }
    },
    onSelect(content, field) {
      if (content == "Enter a different degree") {
        this.diffDegree = true;
        this.isDisabled = false;
        this.$refs.fieldOfStudy.$el.style.opacity = 1;
      } else if (content == "High School Diploma") {
        this.diffDegree = false;
        this.isDisabled = true;
        this.experienceData.field_of_study = "";
        this.$refs.fieldOfStudy.inputValue = "";
        this.$refs.fieldOfStudy.$el.style.opacity = 0.3;
        this.setField(content, field);
      } else {
        this.diffDegree = false;
        this.isDisabled = false;
        this.$refs.fieldOfStudy.$el.style.opacity = 1;
        this.setField(content, field);
      }
    },
    onClickEditForm() {
      localStorage.setItem(
        "education_form_data",
        JSON.stringify(this.experienceData)
      );

      if (this.experienceData.level_of_education == "High School Diploma") {
        this.isDisabled = true;
        this.experienceData.field_of_study = "";
        this.$refs.fieldOfStudy.inputValue = "";
        this.$refs.fieldOfStudy.$el.style.opacity = 0.3;
      } else {
        this.isDisabled = false;
        this.$refs.fieldOfStudy.$el.style.opacity = 1;
      }
      this.editForm();
    },
    cancel() {
      let data = localStorage.getItem("education_form_data");
      if (data) {
        data = JSON.parse(data);
        this.setField(data.college_name, "college_name");
        this.setField(data.college_location, "college_location");
        this.setField(data.level_of_education, "level_of_education");
        this.setField(data.field_of_study, "field_of_study");
        this.setField(data.graduation_month, "graduation_month");
        this.setField(data.education_year, "education_year");
        this.setField(data.training_programs, "training_programs");
        localStorage.removeItem("education_form_data");
      }
      this.cancelEditing();
    },
    openPrefilled() {
      this.$emit("openPrefilled");
    },

    closePrefilled() {
      this.$emit("closePrefilled");
    },
  },
};
</script>

<style lang="scss" scoped>
.mx-datepicker {
  width: 100%;
}
@media (min-width: 992px) {
  .preview-heading__title,
  .preview-heading__title span {
    font-size: 21px !important;
  }
}
</style>
