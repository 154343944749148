<template>
  <div
    class="field field--focused"
    :class="{
      'field--required': required,
      'field--error': isInvalid,
    }"
  >
    <label :for="id" class="field__label">
      {{ label }}
      <sup v-if="required">*</sup>
    </label>

    <vue-google-autocomplete
      class="field__input"
      ref="field"
      :id="id"
      :placeholder="placeholder"
      :types="types"
      :fields="fields"
      :country="country"
      :geolocationOptions="geolocationOptions"
      :value="inputValue"
      @placechanged="getAddressData"
      @inputChange="onInput"
      @focus="focus"
      @blur="blur"
      @keyup="keyup"
    >
    </vue-google-autocomplete>
    <div
      id="input_gap"
      @click.prevent="onEditCursorFocus"
      @touchstart="onEditCursorFocus"
    ></div>
    <transition name="fade">
      <div
        v-if="isInvalid"
        class="error"
        @mouseenter="openErrorMessage"
        @mouseleave="closeErrorMessage"
      >
        <button type="button" class="error__btn">
          <AppIcon componentName="ErrorIcon" />
        </button>

        <transition name="fade">
          <div v-if="isVisibleErrorMessage" class="error__message">
            <slot name="error" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import VueGoogleAutocomplete from "vue-google-autocomplete";
//https://www.npmjs.com/package/vue-google-autocomplete

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    types: {
      type: String,
      default: "address",
    },
    fields: {
      type: Array,
      default: () => [
        "address_components",
        "adr_address",
        "alt_id",
        "formatted_address",
        "geometry",
        "icon",
        "id",
        "name",
        "photo",
        "place_id",
        "scope",
        "type",
        "url",
        "vicinity",
      ],
    },
    country: {
      type: [String, Array],
      default: null,
    },
    geolocationOptions: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
    },
    initFocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: uniqueId("field"),
      onFocus: !!this.value,
      inputValue: this.value,
      isVisibleErrorMessage: false,
    };
  },

  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      const address = { addressData, placeResultData };
      this.$emit("address", address);
    },

    focus() {
      this.onFocus = true;
      this.$emit("focus");
    },

    blur() {
      this.onFocus = Boolean(this.$refs.field.$el.value);
      this.$emit("blur");
    },

    onInput(value) {
      this.inputValue = value.newVal;
      this.$emit("input", value.newVal);
    },
    keyup(e) {
      //backspace
      if (e.keyCode === 8) {
        this.inputValue = this.$refs.field.$el._value;
        this.$emit("input", this.$refs.field.$el._value);
      }
    },

    toggleErrorMessage() {
      this.isVisibleErrorMessage = !this.isVisibleErrorMessage;
    },

    openErrorMessage() {
      this.isVisibleErrorMessage = true;
    },

    closeErrorMessage() {
      this.isVisibleErrorMessage = false;
    },
    onEditCursorFocus() {
      const input = this.$refs.field.$el;
      input.value = "";
      input.value = this.inputValue;
      input.focus();
      input.dispatchEvent(new KeyboardEvent("keydown", { key: "shift" }));
      input.focus();
    },
  },

  beforeMount() {
    this.inputValue = this.value;
    this.onFocus = !!this.value;
  },
  mounted() {
    if (this.initFocus) {
      this.$refs.field.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
.field {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &__label {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    padding: 3px;
    color: $light-gray;
    z-index: 2;
    background-color: #fff;

    sup {
      font-size: 12px;
      line-height: 16px;
      transition: all 0.3s ease;
    }
  }

  &__input {
    display: block;
    width: 100%;
    border: 1px solid #d6d8dc;
    border-radius: 8px;
    height: 56px;
    padding: 10px 55px 10px 16px;
    font-size: 16px;
    position: relative;
    z-index: 1;
    text-overflow: ellipsis;
  }

  &--focused {
    .field__label {
      font-size: 13px;
      color: $body-color;
      top: 0;
      cursor: text;
      line-height: 20px;
    }
  }

  &--error {
    .field__input {
      border: 2px solid $red;
      color: $red;
      background: #fff;
    }

    .field__label {
      color: $red;
    }
  }
}

::v-deep .error {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
      fill: #e26262;
    }
  }

  &__message {
    padding: 12px;
    background-color: #ffe8e8;
    border-radius: 8px;
    position: absolute;
    right: 10px;
    top: 100%;
    width: 210px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: $red;
    z-index: 5;
  }
}
#input_gap {
  z-index: 2;
  right: 3px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 3px;
}
</style>
