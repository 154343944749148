<template>
  <div class="creation-step">
    <div v-if="!isEditing" class="step-heading">
      <h1 class="step-heading__title">Describe your education</h1>
      <p class="step-heading__text">
        List down your education-related information, including the highest
        degree and honors you earned.
      </p>
    </div>

    <draggable
      v-if="educations.length"
      v-model="educations"
      tag="div"
      class="experience"
      v-bind="dragOptions"
      :sort="false"
      :disabled="!!editingExperience"
      handle=".preview-controls__btn--move"
      @start="dragStart"
      @end="dragEnd"
    >
      <transition-group
        type="transition"
        tag="div"
        :name="isDrag ? 'flip-list' : null"
      >
        <div
          v-for="education of educations"
          :key="education.id"
          class="experience__item"
        >
          <EducationsForm
            :isEditing="isEditing"
            :experience="education"
            :disabled="isEditingExperience"
            :activeId="editingExperience"
            :isMobileDisplay="isMobileDisplay"
            :educationLevels="educationLevels"
            @edit="openEditing"
            @cancel="cancelEducationEditing"
            @create="createEducation"
            @update="updateEducation"
            @remove="removeEducation"
            @openPrefilled="openPrefilled"
            @closePrefilled="closePrefilled"
          />
        </div>
      </transition-group>
    </draggable>

    <AddingBlock
      v-if="isShowAddButton(editingExperience)"
      @addBlock="addNewData(editingExperience)"
    >
      Add new education information
    </AddingBlock>

    <div v-if="!isEditing" class="creation-form-header">
      <button
        class="creation-form-header__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
    </div>

    <FooterButton
      v-if="!isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButton>

    <FooterButtonv2
      v-if="isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButtonv2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vue2-datepicker/index.css";

import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
import HelperResumeHash from "@/helpers/resume-hash";
import AppToast from "@/helpers/toast-methods";

import { resumeCreationMixin } from "@/mixins/ResumeCreationStepsMixin";
import { experienceMixin } from "@/mixins/ExperienceMixin";
import EducationsForm from "@/views/resume-creation/step-3/components/EducationsForm";
import AddingBlock from "@/components/creation/AddingBlock";
import HelperEditingHash from "@/helpers/editing-hash";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import {
  COMPONENT_NAMES,
  LOCAL_COMPONENTS_NAMES,
} from "@/helpers/components-names";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import { eventBus } from "@/helpers/event-bus";
import CookieHelper from "@/helpers/cookie-helper";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.EDUCATION,
  components: {
    AddingBlock,
    EducationsForm,
    FooterButton,
    FooterButtonv2,
    draggable,
  },
  mixins: [resumeCreationMixin, experienceMixin, mixpanelMixin],

  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      initialEducations: "resumeCreation/getEducations",
      defaultEducation: "resumeCreation/getDefaultEducation",
    }),
  },

  data() {
    return {
      educations: [],
      nextStepName: CREATING_RESUME_NAMES.STEP_4,
      prevStepName: CREATING_RESUME_NAMES.STEP_2,
      educationLevels: [],
      hideMobileFooter: false,
      isShowBackButton: false,
    };
  },

  methods: {
    resumeHash() {
      return this.isEditing ? HelperEditingHash.get() : HelperResumeHash.get();
    },

    isShowAddButton(isEditingExperience) {
      let hide_add = CookieHelper.getFlag("hide_add", "");
      if (hide_add == "1") {
        if (isEditingExperience) {
          return false;
        }
      }
      return true;
    },

    async addNewData(isEditingExperience) {
      if (isEditingExperience) {
        if (this.checkValidation && this.checkValidation()) {
          if (
            document.querySelector(".experience-form.experience-form--active")
          ) {
            const activeForm = document.querySelector(
              ".experience-form.experience-form--active"
            );
            activeForm
              .querySelector(".experience-form-btns__item--submit")
              .click();
          }

          await this.$store.dispatch("loader/pending");

          setTimeout(() => {
            this.addExperience("educations", this.defaultEducation);
          }, 1000);
        }
      } else {
        this.addExperience("educations", this.defaultEducation);
      }
      await this.$store.dispatch("loader/done");
    },

    async createEducation(education) {
      this.editingExperience = null;

      const temporaryId = education.id;

      delete education.id;
      education.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        const newExperience = await this.$store.dispatch(
          "resumeCreation/createEducation",
          education
        );

        await setIntermediateResume();

        const temporaryExperienceIndex = this.educations.findIndex(
          (localExperience) => {
            return localExperience.id === temporaryId;
          }
        );

        this.mixpanelTrack("V2-ADDEDUC", {
          collegeName: education.college_name,
          collegeLocation: education.college_location,
        });

        // this.$set(this.educations, temporaryExperienceIndex, newExperience);
        this.educations[temporaryExperienceIndex].id = newExperience.id;

        delete this.educations[temporaryExperienceIndex].temporary;
      } catch (e) {
        console.error(e);
        this.cancelEducationEditing();
      }

      await this.$store.dispatch("loader/done");
    },

    async updateEducation(education, resetEducations = true) {
      education.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/updateEducation", education);

        if (resetEducations) {
          await this.$store.dispatch(
            "resumeCreation/getResume",
            this.resumeHash()
          );
          this.cancelEducationEditing();
          // AppToast.toastSuccess("Education was successfully updated!");
        }
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    async removeEducation(educationId) {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/removeEducation", {
          id: educationId,
          hash: this.resumeHash(),
        });
        await this.cancelEducationEditing();
        await this.resetIndexes(
          this.educations,
          (data) =>
            this.$store.dispatch("resumeCreation/updateEducationGroup", data),
          this.cancelEducationEditing
        );
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    cancelEducationEditing() {
      this.editingExperience = null;

      this.setInitialExperience(this.initialEducations, "educations");

      setIntermediateResume();
    },

    async dragEnd() {
      this.isDrag = false;

      await this.resetIndexes(
        this.educations,
        (data) =>
          this.$store.dispatch("resumeCreation/updateEducationGroup", data),
        this.cancelEducationEditing
      );
    },

    checkValidation() {
      return true;
      // if (!this.educations.length) return true;

      // //finding draggable lib component. Our component form is inside draggable
      // const draggable = this.$children.find((componentForm) => {
      //   return componentForm.$options.name === "draggable";
      // });

      // const workHistoryForms = draggable.$children[0].$children.filter(
      //   (componentForm) => {
      //     return (
      //       componentForm.$options.name ===
      //       LOCAL_COMPONENTS_NAMES.EDUCATION_FORM
      //     );
      //   }
      // );

      // let isValidForms = true;
      // workHistoryForms.forEach((educationForm) => {

      //   if (!educationForm.checkValidationForm()) {
      //     isValidForms = false;
      //   }
      // });

      // return isValidForms;
    },

    openPrefilled() {
      this.hideMobileFooter = true;

      this.mixpanelTrack("V2-SUGGESTEDDESC-EDUCATION");
    },

    closePrefilled() {
      this.hideMobileFooter = false;
    },
  },

  async mounted() {
    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },

  async created() {
    let vm = this;
    this.mixpanelTrack("V2-EDUCATION");
    await this.$store.dispatch("loader/pending");

    await this.getInitialResume(this.resumeHash());
    this.educationLevels = await this.$store.dispatch(
      "resumeCreation/getResumeEducationLevels"
    );

    this.setInitialExperience(this.initialEducations, "educations");

    await this.$store.dispatch("loader/done");
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/modules/work-education-skill-steps.scss";
@import "../../../assets/scss/modules/form.scss";

.creation-form-header__preview.order-md-2 {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-header__preview.order-md-2 svg {
  width: 17px;
  fill: #4874e6;
}
</style>
