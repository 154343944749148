<template>
  <div
    class="field field--focused"
    :class="{
      'field--required': required,
      'field--error': isInvalid,
      'field--no-writing': isBlockedWriting,
    }"
  >
    <label :for="id" class="field__label">
      {{ label }}
      <sup v-if="required">*</sup>
    </label>
    <input
      ref="field"
      :id="id"
      :value="inputValue"
      :readonly="disabled || isBlockedWriting"
      type="text"
      class="field__input"
      @focus="focus"
      @blur="blur"
      @change="onChange"
      @input="localError = false"
    />

    <transition name="fade">
      <div
        v-if="isInvalid || localError"
        class="error"
        @mouseenter="openErrorMessage"
        @mouseleave="closeErrorMessage"
      >
        <button type="button" class="error__btn">
          <AppIcon componentName="ErrorIcon" />
        </button>

        <transition name="fade">
          <div v-if="isVisibleErrorMessage" class="error__message">
            <slot v-if="isInvalid" name="error" />
            <p v-if="localError">
              Incorrect date format. <br />
              The date must be in the format DD-MM-YYYY
            </p>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "AppDateField",

  props: {
    value: {
      type: [Date, String],
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "date",
    },

    isBlockedWriting: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.inputValue = "";
          this.onFocus = false;
          this.localError = false;

          return;
        }

        this.inputValue = this.formattingDate(newVal);
        this.onFocus = true;
        this.localError = false;
      },
    },
  },

  computed: {
    dateFormat() {
      switch (this.type.toLowerCase()) {
        case "month":
          return "MMMM";
        case "year":
          return "yyyy";
        case "year2":
          return "yy";
        case "monthandyear":
          return "MMMM yyyy";
        default:
          return "dd-MM-yyyy";
      }
    },
  },

  data() {
    return {
      id: this.$uuid.v4(),
      onFocus: !!this.value,
      inputValue: this.value,
      isVisibleErrorMessage: false,
      localError: false,
    };
  },

  methods: {
    formattingDate(date) {
      if (
        this.type.toLowerCase() === "monthandyear" &&
        date.indexOf(" ") !== -1 &&
        date.indexOf(",") === -1
      ) {
        // if the date is in the format "MMMM yyyy"
        date = date.split(" ")[0] + " " + 1 + ", " + date.split(" ")[1];
      }

      const dateObj = new Date(date);

      return format(dateObj, this.dateFormat);
    },

    onChange(e) {
      this.localError = false;
      const enteringString = e.target.value;

      const parts = enteringString.split("-");
      const enteringDate = new Date(
        Number(parts[2]),
        Number(parts[1]) - 1,
        Number(parts[0])
      );

      if (enteringDate.toString() === "Invalid Date") {
        this.localError = true;

        this.inputValue = this.formattingDate(this.value);
        return;
      }

      this.$emit("change", enteringDate);
    },

    focus() {
      this.onFocus = true;
    },

    blur() {
      this.onFocus = Boolean(this.inputValue);
    },

    toggleErrorMessage() {
      this.isVisibleErrorMessage = !this.isVisibleErrorMessage;
    },

    openErrorMessage() {
      this.isVisibleErrorMessage = true;
    },

    closeErrorMessage() {
      this.isVisibleErrorMessage = false;
    },
  },

  beforeMount() {
    if (!this.value) {
      this.inputValue = "";
      this.onFocus = false;
      this.localError = false;

      return;
    }

    this.inputValue = this.formattingDate(this.value);
    this.onFocus = !!this.value;
  },
};
</script>

<style lang="scss" scoped>
.field {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &__label {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    padding: 3px;
    color: $light-gray;
    z-index: 2;
    background-color: #fff;

    sup {
      font-size: 12px;
      line-height: 16px;
      transition: all 0.3s ease;
    }
  }

  &__input {
    display: block;
    width: 100%;
    border: 1px solid #d6d8dc;
    border-radius: 8px;
    height: 56px;
    padding: 10px 16px 10px 16px;
    font-size: 16px;
    position: relative;
    z-index: 1;

    &:disabled {
      background-color: #fff;
    }
  }

  &--textarea {
    .field__label {
      top: 24px;
    }

    .field__input--textarea {
      resize: none;
      height: 165px;
    }
  }

  &--focused {
    .field__label {
      font-size: 13px;
      color: $body-color;
      top: 0;
      cursor: text;
      line-height: 20px;
    }
  }

  &--no-writing {
    .field__input {
      border: 1px solid #d6d8dc;
      color: $body-color;
      -webkit-text-fill-color: $body-color;
      opacity: 1;
    }
  }

  &--error {
    .field__input {
      border: 2px solid $red;
      color: $red;
      background: #fff;
    }

    .field__label {
      color: $red;
    }

    &.field--password {
      .field__input {
        padding-right: 70px;
      }
    }
  }
}

@media (max-width: 375px) {
  .pmt2 .field__input {
    max-height: 40px !important;
    height: 40px !important;
  }
}
::v-deep .error {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
      fill: #e26262;
    }
  }

  &__message {
    padding: 12px;
    background-color: #ffe8e8;
    border-radius: 8px;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 210px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: $red;
    z-index: 5;
  }
}

::v-deep .password-toggle-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  z-index: 10;

  //&:focus {
  //  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  //}
  //
  //&:hover {
  //  box-shadow: none;
  //}

  svg {
    width: 20px;
    height: 20px;
  }
}

.field--error {
  .password-toggle-btn {
    right: 35px;
  }
}
</style>
